import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Button, Divider, Grid, Header } from "semantic-ui-react";
import { withTranslation } from 'react-i18next';
import JourneyProgress from "./JourneyProgress";
import {
  ImagePlaceholder,
  DescriptionPlaceholder,
  getTranslation,
} from "../../utils/HelperFunctions";
import { connect } from "react-redux";
import {
  getProgressForJourney,
  getLatestUserProgress,
} from "../../actions/journeyActions";
import DynamicImage from "../HelperComponents/DynamicImage";
import { linkToPath } from "../../utils/InternalLinks";

import "./JourneyResumeWrapper.scss";

export class JourneyResume extends Component {
  componentDidMount() {
    const isUserLoggedIn = this.props.isUserLoggedIn;

    if (isUserLoggedIn) {
      const id = this.props.journeyId;
      this.props.getProgressOfJourney(id);
      this.props.getLatestUserProgress();
    }
  }

  componentDidUpdate(prevProps) {
    const prevId = prevProps.journeyId;
    const nextId = this.props.journeyId;

    if (prevId !== nextId) {
      const id = this.props.journeyId;
      this.props.getProgressOfJourney(id);
    }
  }

  journeyProgress() {
    const complete = this.props.pathsComplete;
    const total = this.props.pathsTotal;
    if (complete !== null && total != null) {
      return <JourneyProgress complete={complete} total={total} />;
    }
  }

  resumeClicked() {
    this.props.history.push(
      linkToPath(
        this.props.journeyId,
        this.props.excursion_id,
        this.props.path_id
      )
    );
  }

  render() {
    const { t } = this.props;
    const journey = this.props.latestUserEnrollment.journey;

    return (
      <Grid className="resume-progress">
        <Grid.Row className="journey-resume">
          <Grid.Column
            tablet={7}
            computer={5}
            mobile={16}
            className="journey-resume-image"
          >
            <DynamicImage
              src={journey.image || ImagePlaceholder()}
              alt={journey.image_alt_text || ""}
            />
          </Grid.Column>
          <Grid.Column
            tablet={9}
            computer={10}
            mobile={16}
            className="journey-resume-content"
          >
            <Header as="h2">{getTranslation(journey, "name")}</Header>
            <p>{DescriptionPlaceholder(journey.description)}</p>
            {this.journeyProgress()}
            <Divider />
            <Button
              primary
              style={{ float: "right" }}
              onClick={this.resumeClicked.bind(this)}
            >
              { t("journeyResume.resume") }
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    isUserLoggedIn: store.login.isUserLoggedIn,
    journey: store.journey.journey,
    pathsComplete: store.journey.pathsComplete,
    pathsTotal: store.journey.pathsTotal,
    latestUserEnrollment: store.journey.latestUserEnrollment,
    excursion_id: store.journey.latestUserEnrollment.excursion_id,
    path_id: store.journey.latestUserEnrollment.path_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLatestUserProgress: () => dispatch(getLatestUserProgress()),
    getProgressOfJourney: (id) => {
      dispatch(getProgressForJourney(id));
    },
  };
};

export default withTranslation()(withRouter(
  connect(mapStateToProps, mapDispatchToProps)(JourneyResume)
));
